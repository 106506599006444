<template>
	<ion-page>
		<ion-header>
			<ion-toolbar>
				<ion-buttons slot="start">
					<ion-menu-button></ion-menu-button>
				</ion-buttons>
				<ion-title>{{ $route.meta.title }}</ion-title>
			</ion-toolbar>
		</ion-header>

		<ion-content :fullscreen="true">
			<ion-searchbar
				v-model="keyword"
				placeholder="Buscar..."
			></ion-searchbar>

			<ion-list>
				<ion-item-sliding
					:key="index"
					v-for="(solicitacao, index) in filteredRequests"
				>
<!--					fixme -->
					<ion-item
						button
						lines="none"
                        @click="$router.push({name: `solicitacoes.edit`, params: {id: solicitacao.id}})"
					>
						<ion-icon name="documents-outline" slot="start"></ion-icon>
						<ion-label>Código: {{ solicitacao.id }}<br>
							<ion-note>Solicitante: {{ $_.get(solicitacao,'requester.name') }}</ion-note><br>
							<ion-note>Cliente: {{ solicitacao.customer.business_name }}</ion-note><br>
<!--							<ion-note>Total: {{ solicitacao.invoice_total }}</ion-note>-->
						</ion-label>
					</ion-item>

					<ion-item-options
						side="end"
						v-if="
							$store.state.usuario.permissions['delete_requests_for_anyone']
							|| ($store.state.usuario.permissions['delete_requests_for_yourself'] && $store.state.usuario.id === request.requester.id)
						"
					>
						<ion-item-option
							color="danger"
							@click.prevent.stop="excluir(solicitacao)"
						>
							<ion-label>Excluir</ion-label>
						</ion-item-option>
					</ion-item-options>
				</ion-item-sliding>
			</ion-list>

			<ion-fab v-if="$store.state.usuario.permissions['create_requests_for_anyone'] || $store.state.usuario.permissions['create_requests_for_yourself']" vertical="bottom" horizontal="end" slot="fixed">
				<ion-fab-button @click="$router.push({ name: `solicitacoes.create` })">
					<ion-icon name="add-outline"></ion-icon>
				</ion-fab-button>
			</ion-fab>
		</ion-content>
	</ion-page>
</template>

<script>
	//Ícones Ionic
	import {
		addOutline,
		documentsOutline
	} from "ionicons/icons";
	import { addIcons } from "ionicons";
	addIcons({
		"add-outline": addOutline,
		"documents-outline": documentsOutline,
	});

	//Componentes Ionic
	import {
		IonButtons,
		IonContent,
		IonHeader,
		IonMenuButton,
		IonPage,
		IonTitle,
		IonToolbar,
		IonList,
		IonItem,
		IonItemSliding,
		IonLabel,
		IonIcon,
		IonFabButton,
		IonFab,
		IonItemOption,
		IonItemOptions,
		IonNote,
		IonSearchbar
	} from '@ionic/vue';

	//Firebase
	import { db } from "@/firebase";
	import {confirmAlert, toast} from "@/helpers";

	//Mixins
	import TitleMixin from "@/mixins/TitleMixin";

    //Aux
    import _ from "lodash";

	export default {
		name: "UsuarioIndex",

		data() {
			return {
				requests: [],
				keyword: '',
				carregandoSolicitacoes: false
			}
		},

		computed: {
			filteredRequests() {
				return this.keyword.trim() === ''
					? this.requests
					: this.requests.filter((r) =>
						_.get(r,'customer.name',"").toLowerCase().includes(this.keyword.toLowerCase())
						|| _.get(r,'requester.name',"").toLowerCase().includes(this.keyword.toLowerCase())
						|| _.get(r,'invoice_total',"").toLowerCase().includes(this.keyword.toLowerCase())
						|| _.get(r,'id',"").includes(this.keyword)
				);
			}
		},

		mixins: [
			TitleMixin
		],

		created() {
			const vm = this;
			vm.carregandoSolicitacoes = true;

			const query = this.$store.state.usuario.permissions['view_requests_for_anyone']
				? db.collection('requests').orderBy('created_at', 'desc')
				: db.collection('requests').where('requester', '==', db.doc(`users/${this.$store.state.usuario.id}`)).orderBy('created_at', 'desc');

			this.$bind('requests', query)
				.then(function() {
					//
				})
				.catch(function (err) {
					toast(err);
				})
				.then(function () {
					vm.carregandoSolicitacoes = false;
				});
		},

		methods: {
			async excluir(request) {
				if (
					this.$store.state.usuario.permissions['delete_requests_for_anyone']
					|| (this.$store.state.usuario.permissions['delete_requests_for_yourself'] && this.$store.state.usuario.id === request.requester.id)
				) {
					return confirmAlert(
						`Você tem certeza que deseja excluir a requisição <strong>${request.id}</strong>?`,
						() => {
							db.collection('requests')
								.doc(request.id)
								.delete()
								.then(function() {
									toast("Solicitação excluída com sucesso!");
								}).catch(function(error) {
								toast(`Erro ao tentar excluir solicitação: ${error}`);
							});
						}
					);
				} else {
					toast('Você não tem permissão para esse recurso!');
				}
			}
		},

		components: {
			IonButtons,
			IonContent,
			IonHeader,
			IonMenuButton,
			IonPage,
			IonTitle,
			IonToolbar,
			IonList,
			IonItem,
			IonItemSliding,
			IonLabel,
			IonIcon,
			IonFabButton,
			IonFab,
			IonItemOption,
			IonItemOptions,
			IonNote,
			IonSearchbar
		}
	}
</script>
